import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Type from "./Type";

function Cover() {

  const navigate = useNavigate()

  const submitHandler = (e) => {
    e.preventDefault();
    navigate(`/contact-sales`);
  };

  const scrollToProducts = () => {
    const section = document.querySelector( '#products' );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };

  return (
    <Container fluid className="home-cover-section" id="home">
      <Container className="home-content">
        <Row>
          <Col md={12} className="home-header">
            <h1 style={{ paddingBottom: 30 }} className="heading">
            <span style={{ fontWeight: "bold"}}>Flexible platform for</span>
              <span style={{ color: "#be6adf", fontWeight: "bold"}}> Data Discovery, Valuation and Monetization</span>
            </h1>

            <h4 className="heading-name">
              Valuate, visualize and monetize your data with ease!
            </h4>

            <div className="type">
              <Type />
            </div>
            <div className="mb-1" style={{ textAlign: "center" }}>
              <Button variant="primary" size="lg" onClick={submitHandler} style={{width:180}}>Request Demo</Button>{' '}
              <Button variant="outline-light" size="lg" onClick={scrollToProducts} style={{width:180}}>{' Learn More '}</Button>
            </div>
          </Col>
        </Row>
        <Row className="home-content-bottom"><span>📢🚀We have launched the <a href="https://recommender.dataequity.io/" target="_blank" rel="noreferrer">API Recommender</a> service under the DE Infobot suite🚀. 🗣️The API recommender will provide
        API recommendations for a given use case. Read our blog <a href="https://dataequity.io/blogs/api-recommender" target="_blank" rel="noreferrer">here.</a></span></Row>
      </Container>
    </Container>
  );
}

export default Cover;
