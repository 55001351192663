import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Terms() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={12} className="privacy-description">
            <h1 style={{ fontSize: "2.6em" }}>
              <span className="purple">Terms of Use</span> 
            </h1>
            <p className="privacy-body">
              Last Updated: November 06, 2023
            </p>
            <p className="privacy-body">
              <h1 className="privacy_sub_header">
                <span>AGREEMENT TO OUR LEGAL TERMS</span> 
              </h1>
              <table className="privacy">
                <tr>We are Data Equity Ltd ('Company,' 'we,' 'us,' or 'our'), a registered company located at 18 Arundel Court, Slough, England SL3 7NP, United Kingdom.</tr>
                <tr>We operate the website https://dataequity.io and https://recommender.dataequity.io (the 'Site'), along with any other associated products and services that reference or link to these legal terms (the 'Legal Terms')—collectively known as the 'Services.'</tr>
                <tr>You can reach us via email at support@dataequity.io or by postal mail at 18 Arundel Court, Slough, England SL3 7NP, United Kingdom.</tr>
                <tr>These Legal Terms establish a legally binding agreement between you, whether as an individual or on behalf of an entity ('you'), and Data Equity Ltd regarding your use of the Services. By accessing the Services, you acknowledge that you have read, comprehended, and agreed to abide by all of these Legal Terms. IF YOU DO NOT CONSENT TO ALL OF THESE LEGAL TERMS, YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES, AND YOU MUST CEASE USE IMMEDIATELY.</tr>
                <tr>Supplementary terms and conditions or documents that may be posted on the Services periodically are expressly included here by reference. We retain the exclusive right, at our discretion, to modify or amend these Legal Terms periodically. We will inform you of any changes by updating the 'Last updated' date in these Legal Terms, and you relinquish any entitlement to receive specific notice of each such modification. It is your responsibility to periodically review these Legal Terms to remain informed about updates. By continuing to use the Services after the date on which revised Legal Terms are posted, you will be considered to have accepted and been informed of these changes.</tr>
                <tr>The Services are designed for users who are at least 18 years of age. Individuals under the age of 18 are not allowed to use or register for the Services.</tr>
                <tr>We recommend that you maintain a printed copy of these Legal Terms for your personal records.</tr>
              </table>
              <br></br>
              <h1 className="privacy_sub_header">
                <span>1. OUR SERVICES</span> 
              </h1>
              <table className="privacy">
                <tr>The information made available through the use of our Services is not meant for distribution to, or utilization by, any individual or entity in any jurisdiction or country where such distribution or use would be in violation of the law or regulations, or would necessitate our registration within that jurisdiction or country. Therefore, individuals who opt to access our Services from different locations do so voluntarily and are individually responsible for ensuring compliance with any relevant local laws, where applicable.</tr>
              </table>
              <br></br>
              <h1 className="privacy_sub_header">
                <span>2. INTELLECTUAL PROPERTY RIGHTS</span> 
              </h1>
              <h1 className="privacy_sub_header">
                <span>Our Ownership of Intellectual Property</span> 
              </h1>
              <table className="privacy">
                <tr>We hold the rights or licenses to all the intellectual property assets within our Services. This includes the source code, databases, functionality, software, website designs, audio, video, text, images, and graphics within the Services, collectively referred to as the 'Content,' as well as the trademarks, service marks, and logos embedded in them - (the Marks).</tr>
                <tr>Our Content and Marks are safeguarded by copyright and trademark laws, along with various other intellectual property rights and laws governing fair competition in the United States and across the globe.</tr>
                <tr>The Content and Marks are offered within the Services 'AS IS' and solely for your internal business use.</tr>
              </table>
              <h1 className="privacy_sub_header">
                <span>Your use of our Services</span> 
              </h1>
              <table className="privacy">
                <tr>Provided that you adhere to these Legal Terms, which include the 'PROHIBITED ACTIVITIES' section outlined below, we confer upon you a non-exclusive, non-transferable, and revocable license to:</tr>
                <tr>Access the Services; and</tr>
                <tr>Download or print a portion of the Content, provided you have rightfully accessed it.</tr>
                <tr>This authorization is strictly intended for your internal business purposes.</tr>
                <tr>With the exception of what is detailed in this section or elsewhere within our Legal Terms, no part of the Services, Content, or Marks may be duplicated, replicated, aggregated, republished, uploaded, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial use without our explicit prior written consent.</tr>
                <tr>If you wish to employ the Services, Content, or Marks in a manner beyond what is described in this section or in other sections of our Legal Terms, kindly submit your request to: support@dataequity.io. Should we ever grant you permission to publish, reproduce, or publicly display any aspect of our Services or Content, you must duly acknowledge us as the owners or licensors of the Services, Content, or Marks, and ensure that any copyright or proprietary notice is visible when posting, reproducing, or displaying our Content.</tr>
                <tr>We maintain all rights that are not explicitly granted to you concerning the Services, Content, and Marks.</tr>
                <tr>Violation of these Intellectual Property Rights will be deemed a significant breach of our Legal Terms, resulting in the immediate termination of your right to utilize our Services.</tr>
              </table>
              <h1 className="privacy_sub_header">
                <span>Your submissions</span> 
              </h1>
              <table className="privacy">
                <tr>Prior to utilizing our Services, it is imperative to carefully review this section, as well as the 'PROHIBITED ACTIVITIES' section, to comprehend both (a) the rights you confer to us and (b) the obligations you assume when posting or uploading content through the Services.</tr>
                <tr>Submissions: When you directly transmit any question, comment, suggestion, idea, feedback, or other information related to the Services ('Contributions'), you concur to transfer all intellectual property rights in such Contributions to us. You acknowledge that we shall possess these Contributions and have the unencumbered authority to utilize and distribute them for any lawful purpose, be it commercial or otherwise, without the need for acknowledgment or compensation to you.</tr>
                <tr>Your Accountability for What You Post or Upload: By submitting Contributions through any segment of the Services, you:</tr>
                <ol>
                  <li>confirm that you have read and agree with our 'PROHIBITED ACTIVITIES' and will not post, send, publish, upload, or transmit through the Services any Submission that is illegal, harassing, hateful, harmful, defamatory, obscene, bullying, abusive, discriminatory, threatening to any person or group, sexually explicit, false, inaccurate, deceitful, or misleading;</li>
                  <li>to the extent permissible by applicable law, waive any and all moral rights to any such Submission;</li>
                  <li>warrant that any such Submission are original to you or that you have the necessary rights and licences to submit such Submissions and that you have full authority to grant us the above-mentioned rights in relation to your Submissions; and</li>
                  <li>warrant and represent that your Submissions do not constitute confidential information.</li>
                </ol>
                <tr>You are exclusively accountable for your Contributions, and you explicitly concur to compensate us for any and all losses we may incur due to your violation of (a) this section, (b) the intellectual property rights of any third party, or (c) applicable law.</tr>
              </table>
              <br></br>
              <h1 className="privacy_sub_header">
                <span>3. USER REPRESENTATIONS</span> 
              </h1>
              <table className="privacy">
                <tr>By employing our Services, you make the following representations and warranties:</tr>
                <ol>
                  <li>All registration information you provide will be truthful, precise, up-to-date, and comprehensive.</li>
                  <li>You will uphold the accuracy of such information and promptly modify it as needed.</li>
                  <li>You possess the legal capacity and agree to abide by these Legal Terms.</li>
                  <li>You are not a minor in your residing jurisdiction.</li>
                  <li>You will not access the Services using automated or non-human methods, such as bots, scripts, or similar means.</li>
                  <li>You will not employ the Services for unlawful or unauthorized objectives.</li>
                  <li>Your use of the Services will not breach any relevant laws or regulations.</li>
                </ol>
                <tr>Should you submit information that is incorrect, inaccurate, outdated, or incomplete, we retain the right to suspend or terminate your account and decline any present or future usage of the Services (or any part thereof).</tr>
              </table>
              <br></br>
              <h1 className="privacy_sub_header">
                <span>4. USER REGISTRATION</span> 
              </h1>
              <table className="privacy">
                <tr>Registration might be necessary for accessing the Services. You commit to maintaining the confidentiality of your password and will be liable for all activities carried out using your account and password. We hold the authority to delete, recover, or modify a username you choose if, at our sole discretion, we deem it to be inappropriate, indecent, or otherwise objectionable.</tr>
              </table>
              <br></br>
              <h1 className="privacy_sub_header">
                <span>5. PURCHASES AND PAYMENT</span> 
              </h1>
              <table className="privacy">
                <tr>We accept the following forms of payment:</tr>
                <ol>
                  <li>Visa</li>
                  <li>Mastercard</li>
                  <li>American Express</li>
                  <li>Discover</li>
                  <li>PayPal</li>
                </ol>
                <tr>You consent to supplying current, comprehensive, and accurate purchase and account details for all transactions executed through the Services. Additionally, you commit to promptly updating account and payment particulars, such as your email address, payment method, and payment card expiration date, to facilitate order processing and communication with you. Sales tax, as determined by us, will be added to the purchase price as necessary. We maintain the right to modify prices at our discretion. All payments shall be in US dollars.</tr>
                <tr>You agree to settle all charges at the prevailing prices for your purchases and any associated shipping costs. By placing your order, you authorize us to charge your designated payment provider for these amounts. In the case of orders subject to recurring charges, you grant us permission to charge your payment method on a recurring basis without the need for your prior approval for each recurring charge, until you decide to cancel the relevant order. We reserve the right to rectify any errors or inaccuracies in pricing, even if payment has already been requested or received.</tr>
                <tr>We retain the right to decline any order made through the Services. Additionally, in our sole discretion, we may impose restrictions on or cancel the quantities purchased per individual, per household, or per order. Such limitations may include orders placed using the same customer account, identical payment method, or orders sharing the same billing or shipping address. We also reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers, or distributors.</tr>
              </table>
              <br></br>
              <h1 className="privacy_sub_header">
                <span>6. CANCELLATION</span> 
              </h1>
              <table className="privacy">
                <tr>You have the option to terminate your subscription by reaching out to us using the provided contact details. Your cancellation will be implemented at the conclusion of the ongoing paid term.</tr>
                <tr>If you are dissatisfied with our Services, please do not hesitate to get in touch with us via email at support@dataequity.io.</tr>
              </table>
            
            <br></br>
            <h1 className="privacy_sub_header">
              <span>7. PROHIBITED ACTIVITIES</span> 
            </h1>
            Accessing or using the Services is only permitted for the specific purpose for which we have made them available. The Services should not be utilized for any commercial activities unless explicitly endorsed or approved by us. As a user of the Services, you are prohibited from:
            <ol style={{ textAlign: "left" }}>
              <li>Systematically gathering data or other content from the Services to establish a collection, compilation, database, or directory, whether directly or indirectly, without obtaining written permission from us.</li>
              <li>Attempting to deceive, defraud, or mislead us or other users, especially in an effort to obtain sensitive account information, such as user passwords.</li>
              <li>Bypassing, disabling, or interfering with security-related features of the Services, including those designed to prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services and the Content therein.</li>
              <li>Engaging in behavior that, in our assessment, disparages, tarnishes, or otherwise harms us and/or the Services.</li>
              <li>Employing any information acquired from the Services to harass, abuse, or harm another person.</li>
              <li>Abusing our support services or filing false reports of abuse or misconduct.</li>
              <li>Using the Services in contravention of any applicable laws or regulations.</li>
              <li>Uploading or transmitting (or attempting to upload or transmit) viruses, Trojan horses, or other materials that disrupt another party's uninterrupted use and enjoyment of the Services or disrupt the Services' operation, features, functions, or maintenance, including spamming and excessive use of capital letters.</li>
              <li>Engaging in any form of automated system use, such as using scripts to send comments or messages, or employing data mining, robots, or similar data collection and extraction tools.</li>
              <li>Removing the copyright or other proprietary rights notice from any Content.</li>
              <li>Attempting to impersonate another user or person or use another user's username.</li>
              <li>Uploading or transmitting (or attempting to upload or transmit) any material that acts as an information collection or transmission mechanism, such as clear graphics interchange formats ('gifs'), 1×1 pixels, web bugs, cookies, or similar devices (sometimes referred to as 'spyware' or 'passive collection mechanisms' or 'PCMs').</li>
              <li>Interfering with, disrupting, or imposing an undue burden on the Services or the networks or services connected to the Services.</li>
              <li>Harassing, annoying, intimidating, or threatening any of our employees or agents involved in providing any portion of the Services to you.</li>
              <li>Attempting to circumvent any measures implemented by the Services to prevent or restrict access.</li>
              <li>Deciphering, decompiling, disassembling, or reverse engineering any of the software that forms a part of the Services, except where permitted by applicable law.</li>
              <li>Using, launching, developing, or distributing any automated system, including, without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Services, or using or launching any unauthorized script or other software.</li>
              <li>Engaging in any unauthorized use of the Services, including collecting usernames and/or email addresses of users through electronic or other means for the purpose of sending unsolicited emails or creating user accounts through automated means or under false pretenses.</li>
            </ol>
            <br></br>
              <h1 className="privacy_sub_header">
                <span>8. SERVICES MANAGEMENT</span> 
              </h1>
              <table className="privacy">
                <tr>We retain the right, although it is not obligatory, to:</tr>
                <ol>
                <li>Monitor the Services to detect any violations of these Legal Terms.</li>
                <li>Take appropriate legal action, as determined at our sole discretion, against any individual who violates the law or these Legal Terms. This may involve reporting such users to law enforcement authorities.</li>
                <li>Refuse, limit access to, restrict availability, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof at our sole discretion and without any restrictions.</li>
                <li>Remove from the Services or disable, at our sole discretion and without notice or limitations, any files and content that are excessively large or exert undue strain on our systems.</li>
                <li>Manage the Services in a manner that protects our rights and property and ensures the proper functioning of the Services.</li>
                </ol>
              </table>
              <br></br>
              <h1 className="privacy_sub_header">
                <span>9. PRIVACY POLICY</span> 
              </h1>
              <table className="privacy">
                <tr>We prioritize data privacy and security. We kindly request you to review our Privacy Policy at https://dataequity.io/privacy-policy. By using the Services, you commit to adhering to our Privacy Policy, an integral component of these Legal Terms. It's crucial to understand that our Services are hosted in the United States and the United Kingdom.</tr>
                <tr>If you access the Services from any other region worldwide with distinct laws or regulations governing the collection, use, or disclosure of personal data that may differ from those in the United States and the United Kingdom, your ongoing use of the Services signifies the transfer of your data to the United States and the United Kingdom. You explicitly consent to this transfer and the processing of your data in these countries.</tr>
              </table>
              <br></br>
              <h1 className="privacy_sub_header">
                <span>10. TERM AND TERMINATION</span> 
              </h1>
              <table className="privacy">
                <tr>These Legal Terms will remain in effect for the duration of your use of the Services. Without limiting any other provisions contained in these Legal Terms, we have the authority to disallow access to and use of the Services for any individual, with or without reason, at our sole discretion, without prior notice or any associated liability. This includes the capacity to block specific IP addresses. Grounds for such denial may encompass breaches of any representation, warranty, or covenant outlined in these Legal Terms or violations of applicable laws or regulations.</tr>
                <tr>We retain the right to conclude your use or participation in the Services, delete your account, and eliminate any content or information that you have posted, all at our sole discretion and without prior notice. If we opt to terminate or suspend your account for any reason, you are prohibited from registering or establishing a new account under your own name, a fictitious name, or on behalf of a third party. Furthermore, we maintain the prerogative to pursue suitable legal action, including but not limited to civil, criminal, and injunctive remedies, in conjunction with the termination or suspension of your account.</tr>
              </table>
              <br></br>
              <h1 className="privacy_sub_header">
                <span>11. MODIFICATIONS AND INTERRUPTIONS</span> 
              </h1>
              <table className="privacy">
                <tr>We possess the authority to modify, revise, or remove the content of the Services at our sole discretion, without prior notice, and for any reason. Nevertheless, we are not obligated to update any information on our Services. Any alterations, adjustments in pricing, suspensions, or terminations of the Services will not render us liable to you or any third party.</tr>
                <tr>We cannot guarantee uninterrupted availability of the Services. There may be instances where we encounter hardware, software, or other issues that necessitate maintenance, leading to interruptions, delays, or errors. We reserve the right to modify, revise, update, suspend, discontinue, or otherwise modify the Services at any time, without prior notification to you. You acknowledge that we hold no responsibility for any losses, damages, or inconveniences stemming from your inability to access or utilize the Services during periods of downtime or Service discontinuation.</tr>
                <tr>These Legal Terms do not impose an obligation on us to maintain and support the Services or provide any corrections, updates, or releases related to them.</tr>
              </table>
              <br></br>
              <h1 className="privacy_sub_header">
                <span>12. GOVERNING LAW</span> 
              </h1>
              <table className="privacy">
                <tr>These Legal Terms are subject to and interpreted in accordance with the laws of the United Kingdom, and the utilization of the United Nations Convention on Contracts for the International Sale of Goods is explicitly excluded. If you are an EU resident and a consumer, you also retain the rights and protections granted to you by the mandatory provisions of your home country's laws. Data Equity Ltd and you mutually consent to the non-exclusive jurisdiction of the courts in United Kingdom, allowing you to assert your consumer protection rights under these Legal Terms either in the United Kingdom.</tr>
              </table>
              <br></br>
              <h1 className="privacy_sub_header">
                <span>13. CORRECTIONS</span> 
              </h1>
              <table className="privacy">
                <tr>Information presented on the Services may include typographical mistakes, inaccuracies, or omissions, such as inaccuracies in descriptions, pricing, availability, and other details. We maintain the authority to rectify any errors, inaccuracies, or omissions and to modify or refresh the information on the Services without prior notification.</tr>
              </table>
              <br></br>
              <h1 className="privacy_sub_header">
                <span>14. DISCLAIMER</span> 
              </h1>
              <table className="privacy">
                <tr>THE SERVICES ARE MADE AVAILABLE ON AN "AS-IS" AND "AS-AVAILABLE" BASIS. YOU CONSENT THAT YOUR USE OF THE SERVICES IS SOLELY AT YOUR OWN RISK. TO THE UTMOST EXTENT PERMITTED BY LAW, WE RENOUNCE ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR UTILIZATION OF THEM. THESE INCLUDE, BUT ARE NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</tr>
                <tr>WE DO NOT PROVIDE ANY WARRANTIES OR ASSURANCES ABOUT THE ACCURACY OR COMPLETENESS OF THE CONTENT ON THE SERVICES OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES. WE ACCEPT NO LIABILITY OR RESPONSIBILITY FOR:</tr>
                <tr>1. ERRORS, MISTAKES, OR INACCURACIES IN CONTENT AND MATERIALS.</tr>
                <tr>2. ANY TYPE OF PERSONAL INJURY OR PROPERTY DAMAGE RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES.</tr>
                <tr>3. UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR PERSONAL AND FINANCIAL INFORMATION STORED THEREIN.</tr>
                <tr>4. INTERRUPTIONS OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES.</tr>
                <tr>5. BUGS, VIRUSES, TROJAN HORSES, OR SIMILAR MALICIOUS SOFTWARE TRANSMITTED TO OR THROUGH THE SERVICES BY THIRD PARTIES.</tr>
                <tr>6. ANY ERRORS OR OMISSIONS IN CONTENT AND MATERIALS, OR FOR ANY LOSSES OR DAMAGES RESULTING FROM THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES.</tr>
                <tr>WE DO NOT WARRANT, SUPPORT, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY LINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN BANNERS OR OTHER ADVERTISEMENTS. WE ARE NOT INVOLVED IN OR RESPONSIBLE FOR MONITORING TRANSACTIONS BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. JUST AS WHEN YOU PURCHASE A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR ENVIRONMENT, YOU SHOULD EXERCISE CAUTION AND USE YOUR BEST JUDGMENT.</tr>
              </table>
              <br></br>
              <h1 className="privacy_sub_header">
                <span>15. LIMITATIONS OF LIABILITY</span> 
              </h1>
              <table className="privacy">
                <tr>UNDER NO CIRCUMSTANCES WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE RESPONSIBLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, EXEMPLARY, CONSEQUENTIAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO LOST PROFITS, LOST REVENUE, DATA LOSS, OR OTHER DAMAGES ARISING FROM YOUR UTILIZATION OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</tr>
                <tr>NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE LEGAL ACTION WILL AT ALL TIMES BE LIMITED TO THE AMOUNT, IF ANY, THAT YOU HAVE PAID TO US DURING THE SIX (6) MONTH PERIOD PRECEDING THE OCCURRENCE OF ANY LEGAL CLAIM. CERTAIN STATE LAWS IN THE U.S. AND INTERNATIONAL LAWS MAY NOT PERMIT RESTRICTIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS ARE APPLICABLE TO YOU, SOME OR ALL OF THE AFOREMENTIONED DISCLAIMERS OR RESTRICTIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.</tr>
              </table>
              <br></br>
              <h1 className="privacy_sub_header">
                <span>16. INDEMNIFICATION</span> 
              </h1>
              <table className="privacy">
                <tr>You agree to protect, compensate, and absolve us, along with our subsidiaries, affiliates, and all our respective officers, agents, partners, and employees, against any losses, damages, liabilities, claims, or demands, including reasonable legal fees and expenses, brought forth by a third party due to or arising from:</tr>
                <tr>1. Your utilization of the Services.</tr>
                <tr>2. A breach of these Legal Terms.</tr>
                <tr>3. Any breach of the representations and warranties you have made in these Legal Terms.</tr>
                <tr>4. Your infringement upon the rights of a third party, including, but not limited to, intellectual property rights.</tr>
                <tr>5. Any overt harmful actions towards another user of the Services with whom you've connected via the Services.</tr>
                <tr>However, we maintain the right, at your expense, to assume exclusive defense and control of any matter for which you are obligated to indemnify us, and you consent to cooperating, at your expense, in our defense against such claims. We will make reasonable efforts to inform you of any such claim, action, or legal proceeding subject to this indemnification as soon as we become aware of it.</tr>
              </table>
              <br></br>
              <h1 className="privacy_sub_header">
                <span>17. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</span> 
              </h1>
              <table className="privacy">
                <tr>By accessing the Services, sending us emails, or filling out online forms, you engage in electronic communications. You provide your consent to receive electronic communications and acknowledge that all agreements, notifications, disclosures, and other communications we send to you electronically, whether via email or through the Services, fulfill any legal stipulation that such communication be in written form. YOU HEREBY CONSENT TO THE UTILIZATION OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER DOCUMENTS, AS WELL AS THE ELECTRONIC TRANSMISSION OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR CONCLUDED BY US OR VIA THE SERVICES. You hereby relinquish any rights or obligations mandated by any statutes, regulations, rules, ordinances, or other laws in any jurisdiction that call for an original signature, or the delivery or retention of non-electronic records, or require payments or the provision of credits through methods other than electronic means.</tr>
              </table>
              <br></br>
              <h1 className="privacy_sub_header">
                <span>18. MISCELLANEOUS</span> 
              </h1>
              <table className="privacy">
                <tr>These Legal Terms, along with any policies or operational guidelines we publish on our Services or in connection with them, constitute the complete agreement and mutual understanding between you and us. Our failure to exercise or enforce any of the rights or provisions outlined in these Legal Terms will not be considered a waiver of those rights or provisions. These Legal Terms are applicable to the fullest extent permitted by law. We have the ability to transfer our rights and responsibilities to others at any time. We are not liable for any losses, damages, delays, or failures to act caused by circumstances beyond our reasonable control.</tr>
                <tr>If a court determines that any provision or part of a provision in these Legal Terms is unlawful, void, or unenforceable, that specific provision or part of the provision is separable from the rest of these Legal Terms and will not impact the validity and enforceability of the remaining provisions. The use of these Legal Terms or our Services does not create a joint venture, partnership, employment, or agency relationship between you and us. You agree that these Legal Terms will not be construed against us merely because we drafted them.</tr>
                <tr>Furthermore, you waive any defenses based on the electronic form of these Legal Terms and the absence of signatures by the parties to execute these Legal Terms.</tr>
              </table>
              <br></br>
              <h1 className="privacy_sub_header">
                <span>19. CONTACT US</span> 
              </h1>
              <table className="privacy">
                <tr>In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us at:</tr>
                <tr>Data Equity Ltd</tr>
                <tr>18 Arundel Court</tr>
                <tr>Slough, England SL3 7NP</tr>
                <tr>United Kingdom</tr>
                <tr>support@dataequity.io</tr>
              </table>
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Terms;
