
import React, { useState } from 'react'
import { Container, Row, Col } from "react-bootstrap";
import axios from 'axios'
import validator from 'validator'
import { BiMailSend } from "react-icons/bi";


const ContactSales = () => {
  const [formStatus, setFormStatus] = useState('Send Message')
  const [errors, setErrors] = useState({})
  const [success, setSuccess] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [organisation, setOrganisation] = useState('')
  const [designation, setDesignation] = useState('')
  const [message, setMessage] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    setFormStatus('Sending...')
    let conFom = {
      name,
      email,
      message: `Org: ${organisation}, Designation: ${designation}, Phone: ${phone}, Message: ${message}`
    }
    axios({
        method: "POST",
        url:"https://api.dev.dataequity.io/website/sendmail",
        data: conFom
      }).then((response)=>{
        if (response.data.status === 'success') {
            setSuccess("Submitted successfully");
            resetForm()
            setFormStatus('Submit')
        } else {
            setErrors({"submit": "Error!"});
        }
      })
  }

  const resetForm = () => {
    setName('');
    setEmail('');
    setMessage('');
    setOrganisation('');
    setDesignation('');
    setPhone('');
  }

  const onOrganisationChange = (event) => {
    const newName = event.target.value
    setOrganisation(newName)

    if (newName.match(/^\s+$/)) {
      setErrors({"organisation": "Please enter a valid organisation"});
    } else {
      setErrors({"organisation": ""});
    }
  }

  const onPhoneChange = (event) => {
    const newName = event.target.value
    setPhone(newName)

    if (newName.match(/^\s+$/)) {
      setErrors({"phone": "Please enter a valid phone number"});
    } else {
      setErrors({"phone": ""});
    }
  }

  const onDesignationChange = (event) => {
    const newName = event.target.value
    setDesignation(newName)

    if (newName.match(/^\s+$/)) {
      setErrors({"designation": "Please enter a valid designation"});
    } else {
      setErrors({"designation": ""});
    }
  }

  const onNameChange = (event) => {
    const newName = event.target.value
    setName(newName)

    if (newName.match(/^\s+$/)) {
        setErrors({"name": "Please enter a valid name"});
    } else {
        setErrors({"name": ""});
    }
  }

  const onEmailChange = (event) => {
    const email = event.target.value
    setEmail(email)

    if (!validator.isEmail(email)) {
        setErrors({"email": "Please enter a valid email"});
    } else {
        setErrors({"email": ""});
    }
  }

  const onMessageChange = (event) => {
    setMessage(event.target.value)
  }

  return (
    <Container fluid className="contact-sales-section">
      <Container className="contact-sales">
      <Row>
        <Col md={2} >
          <div className="container mt-5">
            <BiMailSend className='mailIcon' />
            <h3 style={{ fontSize: "1.6em", paddingTop: "50px" }}>
                We will get in touch within 24 hours
            </h3>
          </div>
        </Col>
        <Col md={10} style={{ padding: 10, border: '1px solid #fff' }}>
        <div className="container mt-5">
            <h1 style={{ fontSize: "2.1em", paddingBottom: "10px" }}>
                Contact Sales
            </h1>
            <span style={{ color: "green" }}>{success}</span>
            <span style={{ color: "red" }}>{errors["submit"]}</span>
            <form onSubmit={handleSubmit} method="POST">
              <div className="form-group">
                <label className="d-flex my-1 me-2" htmlFor="name">Name</label>
                <span style={{ color: "red" }}>{errors["name"]}</span>
                <input name="name" id="name" type="text" className="form-control" value={name} 
                    placeholder="Please enter your full name" onChange={onNameChange} required/>
              </div>

              <div className="form-group">
                <label className="d-flex my-1 me-2" htmlFor="email">Email address</label>
                <input name="email" id="email" type="email" className="form-control" value={email} 
                    placeholder="Please enter your email" onChange={(e) => onEmailChange(e)} required/>
                <small id="emailHelp" className="form-text text-muted">
                    We'll never share your email with anyone else.<a className="tc" href="/terms-of-use">Terms of use</a>
                </small>
                <span style={{ color: "red" }}>{errors["email"]}</span>
              </div>

              <div className="form-group">
                <label className="d-flex my-1 me-2" htmlFor="name">Phone Number</label>
                <span style={{ color: "red" }}>{errors["phone"]}</span>
                <input name="phone" id="phone" type="text" className="form-control" value={phone} 
                    placeholder="Please enter your phone number" onChange={onPhoneChange} required/>
                <small id="emailHelp" className="form-text text-muted">
                    We'll never share your phone number with anyone else.<a className="tc" href="/terms-of-use">Terms of use</a>
                </small>
              </div>

              <div className="form-group">
                <label className="d-flex my-1 me-2" htmlFor="name">Organisation</label>
                <span style={{ color: "red" }}>{errors["organisation"]}</span>
                <input name="organisation" id="organisation" type="text" className="form-control" value={organisation} 
                    placeholder="Please enter the name of your organisation" onChange={onOrganisationChange} required/>
              </div>

              <div className="form-group">
                <label className="d-flex my-1 me-2" htmlFor="name">Designation</label>
                <span style={{ color: "red" }}>{errors["designation"]}</span>
                <input name="designation" id="designation" type="text" className="form-control" value={designation} 
                    placeholder="Please enter your designation" onChange={onDesignationChange} required/>
              </div>

              <div className="form-group">
                <label className="d-flex my-1 me-2" htmlFor="message1">Message</label>
                <textarea name="message" id="message1" className="form-control" rows="5" value={message} 
                    placeholder="Please enter your message" onChange={e => onMessageChange(e)} required/>
              </div>

              <br/>
              <button type="submit" className="btn btn-primary">{formStatus}</button>
            </form>
        </div>
      </Col>
    </Row>
    </Container>
    </Container>
  )
}

export default ContactSales;
